@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: theme(colors.secondary);
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0px;
}

a {
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: "Monoton";
  src: url("./assets/fonts/Monoton-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Concert One";
  src: url("./assets/fonts/ConcertOne-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
